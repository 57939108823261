import injector from '@/scaffold/injector';

/**
 * Create a Module
 * @param {Object} config - Moduleconfig
 * @return {Object} - Module
 */
const createModule = (config) => {
    const module = {
        config,
        selector: config.selector || [
            '[data-module="' + config.name + '"]',
            '[data-module^="' + config.name + ' "]',
            '[data-module$=" ' + config.name + '"]',
            '[data-module~=" ' + config.name + ' "]'
        ].toString(),
        injectedConstructor: (config.dependencies && config.dependencies.length)
            ? injector.resolve([null, ...config.dependencies], config.constructor)
            : config.constructor,
        instances: []
    };

    /**
     * Init this module for every Element found with the selector
     * @param {Element} [scope = document] - When given, only inits Modules inside given scope
     * @return {Promise} - Return Promise to check when all module instances have been initialized
     */
    module.init = async (scope = document) => {
        return Promise.all(Array.from(scope.querySelectorAll(module.selector)).map(async (el) => {
            // prevent creating instance if one already exists for element
            if (el[`instance-${module.config.name}`]) {
                return;
            }

            const state = await Promise.resolve(module.injectedConstructor({
                name: module.config.name, // TODO: Is this needed?
                el,
                state: {}, // TODO: chould already have all mixins applied
                options: Object.assign({}, module.config.options || {}, JSON.parse(el.getAttribute('data-' + config.name + '-options')))
            }));

            el[`instance-${module.config.name}`] = state || true;

            module.instances.push({
                el,
                state
            });

            return state;
        }));
    };

    /**
     * Destroy all instances in given scope
     * @param {Element} [scope = document] - When given, only destroys Modules inside given scope
     */
    module.destroy = (scope = document) => {
        const elements = Array.from(scope.querySelectorAll(module.selector));

        module.instances = module.instances.reduce((acc, instance) => {
            if (elements.includes(instance.el)) {
                if (instance.state && instance.state.destroy) {
                    instance.state.destroy();
                }

                el[`instance-${module.config.name}`] = null;
            } else {
                acc.push(instance);
            }

            return acc;
        }, []);
    };

    return module;
};

const createApp = ({ state = {}, configs }) => {
    state.modules = {};
    state.configs = configs;

    // Create modules
    state.configs.forEach((config) => {
        const module = createModule(config);
        state.modules[module.config.name] = module;
    });

    state.init = (scope = document) => {
        return Promise.all(Object.values(state.modules)
            .filter(module => module.config.autoInit !== false)
            .map(module => {
                return module.init(scope);
            }));
    };

    state.destroy = (scope = document) => {
        Object.values(state.modules).forEach(module => {
            module.destroy(scope);
        });
    };

    state.initialized = state.init();

    return state;
};

export const getModule = (app, moduleName) => {
    return window[app].modules[moduleName];
};

export default createApp;
